import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  inputFormContainer,
  headerTitle,
} from "../components/layout.module.css";
import Layout from "../components/layout";
import { VerifiedProductItem } from "./index";
import { PageProps, navigate } from "gatsby";
import Images from "react-bootstrap/Image";

const isBrowser = typeof window !== "undefined";
const complete = ({ location }: PageProps) => {
  const stateData = location.state as VerifiedProductItem;
  if (stateData) {
    return (
      <Layout>
        <div className={inputFormContainer} style={{ textAlign: "center" }}>
          <h5 className={headerTitle}>
            {" "}
            Successful submission of warranty registration for
          </h5>
          <Images
            alt="product"
            src={stateData.thumbnail}
            width="200px"
            height="auto"
          />
          <h5 className="mt-3">{stateData.productName}</h5>
          <p className="mt-4">
            You will soon receive an e-warranty information booklet via email
            and/or SMS.
          </p>
          <p className="mt-4">
            <strong>DISCLAIMER</strong>
            <br /> Successful registration does not guarantee the warranty
            claim. The validity of the warranty will be verified based on the
            uploaded receipt photo upon claim.
          </p>
        </div>
      </Layout>
    );
  } else {
    if (isBrowser) {
      navigate("/", { replace: true });
    }
    return <Layout></Layout>;
  }
};

export default complete;
